import React, { useEffect, useState } from 'react';
import styles from './styles/index.module.css';

import { Button, CardTitle, Description, Input, SubTitle } from '../../Components/CustomComponents';
import { Col, Container, Form, Row } from 'react-bootstrap';
// import { WEB_ROUTES } from '../../constants';
import { PAYMENT_TYPE } from './PaymentType';

// icon
import { BiSolidEdit } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import CustomRadioButton from '../../Components/CustomComponents/CustomRadioButton';
import { useGetCoinsConversationQuery, useGetPaymentMethodQuery, usePurchaseCoinsMutation } from '../../Data/services/Payment';
import BeatLoader from 'react-spinners/BeatLoader';
import Toast, { SuccessToast } from '../../Hooks/useToast';
import { setUserObject } from '../../Data/Features/authSlice';
import { useDispatch } from 'react-redux';

import DePayWidgets, { trigger } from "@depay/widgets";
const { utils } = require("ethers");

const Currency = ({ setPage }) => {
    const dispatch = useDispatch();
    const { data, error, isLoading, isFetching } = useGetCoinsConversationQuery();
    const { data: cardsData, error: cardsDataError, isLoading: cardsLoading, isFetching: cardsDataFetching } = useGetPaymentMethodQuery();
    const [purchaseCoin, { isError, isLoading: isPurchaseLoading, isSuccess }] = usePurchaseCoinsMutation()
    const [selectedDubbCoins, setSelectedDubbCoins] = useState(0)
    const [selectedCard, setSelectedCard] = useState(null)
    const [loading, setLoading] = useState(false)
    const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.BITCOIN);

    const handleRadioChange = (value) => {
        setPaymentType(value); // Update the state with the selected value
        setSelectedCard(null)
    };

    // useEffect(() => {
    //     console.log(paymentType)

    //     return () => {

    //     }
    // }, [paymentType])

    const hanPurchaseCoins = async () => {
        if (paymentType === PAYMENT_TYPE.DEBIT_CARD) {
            setLoading(true)
            let payload = {
                card_slug: selectedCard?.slug,
                coins: selectedDubbCoins,
            }
            try {
                let response = await purchaseCoin(payload)
                if (response?.error?.data || isError) {
                    Toast(response?.error?.data?.message, "error");
                }
                console.log(response);

                SuccessToast(response?.data?.message);
                dispatch(setUserObject(response?.data));
            }
            catch (err) {
                Toast("Something went wrong.", "error");
            }
            finally {
                setLoading(false)
            }
        }

        else {
            const address = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";

            if (!utils.isAddress(address)) {
                console.log("Invalid address!");
            } else {
                const checksummedAddress = utils.getAddress(address);
                console.log("Checksummed Address:", checksummedAddress);

                DePayWidgets.Payment({
                    open: true,
                    accept: [
                        {
                            blockchain: "ethereum", // 
                            token: checksummedAddress, // Token contract address (e.g., WETH)
                            amount: 0.1, // Amount in the token
                            receiver: "0x9CA3dda3CC3464238f9aE9a001696bbA7e71dc81",
                        },
                    ],
                    // connected: (address) => {
                    //     console.log("Wallet is connected");
                    // },
                    // closed: () => {
                    //     console.log("Widget is closed");
                    // },
                    // sent: (transaction) => {
                    //     console.log("Transaction is sent");
                    // },
                    succeeded: async (transaction, payment) => {
                        setLoading(true)
                        let payload = {
                            transaction_id: transaction.transaction,
                            coins: selectedDubbCoins,
                        }
                        try {
                            let response = await purchaseCoin(payload)
                            if (response?.error?.data || isError) {
                                Toast(response?.error?.data?.message, "error");
                            }
                            SuccessToast(response?.data?.message);
                            dispatch(setUserObject(response?.data));
                        }
                        catch (err) {
                            Toast("Something went wrong.", "error");
                        }
                        finally {
                            setLoading(false)
                        }
                    },
                    failed: (transaction, error, payment) => {
                        Toast("Failed to purchase coins.", "error");
                    },
                    critical: (error) => {
                        Toast("Failed to purchase coins.", "error");
                    },
                    error: (error) => {
                        Toast("Failed to purchase coins.", "error");
                    }
                });
            }
        }
    }
    return (
        <div className={styles.Currency}>

            <div
                className={styles.headerContainer}
            >
                <div className={styles.titleContainer}>

                    <SubTitle
                        classes='akiraFont'
                        text={'Currency'}
                    />
                    <Description
                        text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}
                        classes='mt-1 fs-8'
                    />

                </div>

            </div>

            <Row className='my-3' >
                <Col sm={12} md={5}>

                    <div className={styles.showCoins}>

                        <div className={`${styles.dc} bigeroverFont`}>DC</div>

                        <div className={` d-flex flex-column text-white bigeroverFont p-1`}>
                            <p className='m-0 fs-1' style={{ lineHeight: 1 }}> 500 </p>
                            <p className='m-0' > Dubb Coins </p>
                        </div>


                        <div className={styles.plusIcons} onClick={() => setSelectedDubbCoins((prev) => prev + 500)}>
                            <FiPlus color='white' />
                        </div>

                    </div>
                </Col>
                <Col sm={12} md={5}>
                    <div className={styles.connectCoinBox}>
                        <p className='m-0' > Connect BitCoin Wallet </p>

                    </div>
                </Col>
            </Row>

            <SubTitle
                classes='akiraFont fs-5 mb-3 mt-3'
                text={'Purchase Dub Coins'}
            />

            <SubTitle
                classes='fw-bold fs-5'
                text={'Enter the Coin amount'}
            />
            <Description
                text={`1 Dub Coin equals to ${data?.data?.text && parseInt(data?.data?.text)} USD`}
                classes='mt-1 fs-8 mb-4'
            />

            <div className='my-3 d-flex flex-row' >


                <div className='px-3 py-2 rounded-4 text-center d-flex align-items-center justify-content-center' style={{ backgroundColor: '#202127' }}>
                    <p className='m-0'> {selectedDubbCoins} Dub Coins </p>

                </div>

                <div className='p-3 text-center' >
                    <p className='m-0'>=</p>
                </div>

                <div className='px-4 py-2 rounded-4 text-center' style={{ backgroundColor: '#202127' }}>
                    <div className='flex-column'>
                        <p className='m-0 fs-8' style={{ color: '#F1C256' }}>Total Amount</p>
                        {paymentType === PAYMENT_TYPE.DEBIT_CARD ? <p className='m-0'> {`$${selectedDubbCoins * parseInt(data?.data?.text)} USD`} </p> : ''}
                        {paymentType === PAYMENT_TYPE.BITCOIN ? <p className='m-0'> 0.15 BTC </p> : ''}
                    </div>
                </div>

            </div>

            <Row>
                <Col sm={12} md={6} lg={5}>

                    <SubTitle
                        classes='fw-bold fs-5'
                        text={'Adding Coins via'}
                    />

                    <CustomRadioButton
                        cardNumber="****  ****  ****  4567"
                        cardType={PAYMENT_TYPE.DEBIT_CARD}
                        iconImage="images/master_card_icon.png"
                        onChange={handleRadioChange}
                        currentValue={paymentType}
                    />


                    {paymentType === "Debit Card" ? cardsLoading ? (
                        <BeatLoader size={30} color="white" />
                    ) : (
                        cardsData && cardsData?.data?.length > 0 ? cardsData?.data?.map((dt) => (
                            <Row key={dt?.slug}>
                                <Col sm={12} md={12}>
                                    <div className={`w-100 rounded-4 p-4 mb-3 ${styles.paymentCard} ${dt?.slug === selectedCard?.slug && styles.active}`} onClick={() => setSelectedCard(dt)}>
                                        <p className="mb-3 bigeroverFont fs-4 lc-1">{dt?.name}</p>
                                        <p className="mb-2 bigeroverFont fs-5 lc-1">**** **** **** {dt?.last_four}</p>
                                        <p className="m-0">{`${dt?.expiry_month}/${dt?.expiry_year}`}</p>
                                    </div>
                                </Col>
                            </Row>
                        )) : (
                            <span className={styles.spanNoDataFound}>No Card Available</span>
                        )) : ''
                    }
                    <CustomRadioButton
                        cardNumber="#4512 4125 0344"
                        cardType={PAYMENT_TYPE.BITCOIN}
                        iconImage="images/bit_coin_icon.png"
                        onChange={handleRadioChange}
                        currentValue={paymentType}
                    />

                    <Button
                        text={loading ? <BeatLoader size={10} /> : "Purchase Coins"}
                        classes={`mt-3 mb-2 bg p-3 ${loading || selectedDubbCoins == 0 ? "bg-dark-subtle" : ''}`}
                        onClick={hanPurchaseCoins}
                        disabled={selectedDubbCoins <= 0 ? true : loading}
                    />

                </Col>
            </Row>


        </div >
    )
}

export default Currency
